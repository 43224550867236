import React from "react";
import Hero from "../../components/Services/AISolutions/Hero";
import Footer from "../../components/Footer/Footer";
import Features from "../../components/Services/AISolutions/Features";
import AI from "../../components/Background/AI"; // Import the FixedImage component

const AISolution = () => {
  return (
    <>
      <AI /> {/* Include the FixedImage component */}
      <Hero />
      <Features />
      <Footer />
    </>
  );
};

export default AISolution;
