import React, { useState, useEffect } from "react";
import styled from "styled-components";
import backgroundImage from "../../components/assets/d.jpg";

const ImageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: opacity 2s ease;
  opacity: ${({ opacity }) => opacity};
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const FixedImage = () => {
  const [opacity, setOpacity] = useState(0.1);

  useEffect(() => {
    // Create an interval to toggle opacity between 0.1 and 0.5 every 2 seconds
    const interval = setInterval(() => {
      setOpacity((prevOpacity) => (prevOpacity === 0.02 ? 0.3 : 0.02));
    }, 1500);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return <ImageContainer opacity={opacity} />;
};

export default FixedImage;
