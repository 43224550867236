import Hero from "../../components/Services/RND/Hero";
import Features from "../../components/Services/RND/Features";
import Rnd from "../../components/Background/Rnd"
import Footer from "../../components/Footer/Footer";
const RND = () => {
  return (
    <>
    <Rnd />
      <Hero />
      <Features />
      <Footer />
    </>
  );
};

export default RND;
