import Hero from "../../components/Services/ProductManufacturing/Hero";
import Features from "../../components/Services/ProductManufacturing/Features";
import Manu from "../../components/Background/Manu"
import Footer from "../../components/Footer/Footer";
const ProductManufacturing = () => {
  return (
    <>
    <Manu />
      <Hero />
      <Features />
      <Footer />
    </>
  );
};

export default ProductManufacturing;
