import Hero from "../../components/Services/DatabaseManagement/Hero";
import Features from "../../components/Services/DatabaseManagement/Features";
import Database from "../../components/Background/Database"
import Footer from "../../components/Footer/Footer";
const DatabaseManagement = () => {
  return (
    <>    <Database />
    <Hero />
    <Features />
    <Footer />
    </>
  );
};

export default DatabaseManagement;
