import Hero from "../../components/Services/TrainingAndWorkshops/Hero";
import Features from "../../components/Services/TrainingAndWorkshops/Features";

import Train from "../../components/Background/Train"
import Footer from "../../components/Footer/Footer";
const TrainingAndWorkshops = () => {
  return (
    <>
    <Train />
      <Hero />
      <Features />
      <Footer />
    </>
  );
};

export default TrainingAndWorkshops;
