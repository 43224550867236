import React from "react";

import { useRef } from "react";

import styled from "styled-components";

import { MdAlternateEmail } from "react-icons/md";

import { CgProfile } from "react-icons/cg";

import { HiOutlineMailOpen } from "react-icons/hi";

import { AiFillLinkedin, AiFillInstagram } from "react-icons/ai";

import { BsFacebook } from "react-icons/bs";

import { FiMail, FiPhoneCall } from "react-icons/fi";

import { Slide, Zoom, Fade } from "react-awesome-reveal";

import emailjs from "@emailjs/browser";

const Footer = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_k6ahh1e",
      "template_053fg5j",
      form.current,
      "Gq9d-RvwwRrxDAbmH"
    );
    // To cleat the input fields after sending the data
    e.target.reset();
  };
  return (
    <>
      <Container id="footer">
        <Form>
          <form ref={form} onSubmit={sendEmail}>
            <div className="name">
              <span>
                <CgProfile />
              </span>
              <input type="name" name="name" placeholder="Fullname..." />
            </div>
            <div className="email">
              <span>
                <MdAlternateEmail />
              </span>
              <input type="email" name="email" placeholder="Email..." />
            </div>
            <div className="message">
              <span className="messageIcon">
                <FiMail />
              </span>
              <textarea name="message" cols="30" rows="10" placeholder="Message..."></textarea>
            </div>
            <Button>Submit</Button>
          </form>
        </Form>

        <Profile>
          <h1>Get in Touch</h1>

          <div className="links">
            <h1>Contact us directly:</h1>
            <div>
              <span>
                <FiPhoneCall />
              </span>

              <a href="tel:+919973743902">+91 997 374 3902</a>
            </div>
            <div>
              <span>
                <HiOutlineMailOpen />
              </span>

              <a href="mailto:connect@blackcrabit.com">
                connect@blackcrabit.com
              </a>
            </div>
          </div>
        </Profile>
      </Container>
      <Container>
        <Profile>
          <h2>Visit our profiles</h2>
          <div className="profiles">
            <div className="icons">
              <Zoom>
                <span>
                  <a href="https://www.instagram.com/blackcrabit?igsh=MTkwNTU1anh1bTIzeA==">
                    <AiFillInstagram />
                  </a>
                </span>
              </Zoom>
              <Zoom>
                <span>
                  <a href="http://linkedin.com/in/blackcrab-it-private-limited-250421306">
                    <AiFillLinkedin />
                  </a>
                </span>
              </Zoom>
{/*               <Zoom>
                <span>
                  <a href="/">
                    <BsFacebook />
                  </a>
                </span>
              </Zoom> */}
            </div>
          </div>
        </Profile>
      </Container>
      <Fade></Fade>
      <Copyright>
        <p>
          ©2023 Blackcrab I&T PVT LTD. All Rights Reserved | Terms and
          Conditions | Privacy Policy
        </p>
      </Copyright>
    </>
  );
};

export default Footer;

const Container = styled.div`
  margin-top: 2rem;
  position: relative;
  width: 80%;
  margin: auto;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 650px) {
    flex-direction: column-reverse; /* Switched to column-reverse */
    gap: 3rem;
  }
`;
const Profile = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  .address {
    padding: 1rem 0;
    h1 {
      font-size: 1.2rem;
    }

    <h2 > {
    }

    p {
      width: 60%;
      padding-top: 0.5rem;
      @media (max-width: 650px) {
        width: 100%;
      }
    }
  }

  .links {
    h1 {
      font-size: 1.2rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }

    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      a {
        text-decoration: none;
        color: lightgray;
        :hover {
          color: #993399;
        }
      }
    }
  }

  .profiles {
    h1 {
      font-size: 1.2rem;
      padding: 2rem 0;
    }

    .icons {
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
        margin-top: 1.5rem;
        border-radius: 50px;

        :hover {
          box-shadow: 0px 0px 10px 10px rgba(153, 51, 153, 0.8);
        }

        a {
          margin-top: 0.2rem;
          color: #fff;
          font-size: 1.6rem;
        }
      }
    }
  }
`;

const Form = styled.div`
  flex: 1;
  width: 100%;

  
  h1 {
    font-size: 1.3rem;
    padding-bottom: 0.7rem;
  }

  form {
    background-color: #0c0c0c;
    padding: 0.8rem;
    border-radius: 5px;
    .name,
    .email,
    .message {
      display: flex;
      border: 1px solid gray;
      margin-bottom: 0.5rem;
      input,
      textarea {
        width: 100%;
        border: none;
        outline: none;
        color: #fff;
        background-color: transparent;
        padding: 1rem 0.5rem;
      }
      span {
        background-color: #3e3e3e;
        width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .messageIcon {
        align-items: flex-start;
        padding-top: 0.5rem;
      }
    }


    }
  }
`;

const Copyright = styled.div`
  background-color: #000;
  height: 3rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 0.75rem;
  }

  @media (max-width: 768px) {
    /* Adjust styles for small screens */
    height: 2rem;
    p {
      font-size: 0.6rem;
    }
  }
`;

export const Button = styled.button`
  border-radius: 4px;
  background: none;
  white-space: nowrap;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 550;
  color: #fff;
  outline: none;
  border: 1px solid #fff;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin-bottom: 2rem;

  &:before {
    background: #fff;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: all 0.6s ease;
    width: 100%;
    height: 0%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:hover:before {
    height: 500%;
  }

  &:hover {
    transform: scale(1.05); /* Scale the container on hover */
    transition: transform 0.5s ease; /* Transition the scaling with a slow speed */
    box-shadow: 0 0 32px 2px #59256f; /* Apply the box shadow on hover */
    color: black;
  }
`;
