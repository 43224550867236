import Hero from "../../components/Services/MachineLearning/Hero";
import Features from "../../components/Services/MachineLearning/Features";
import ML from "../../components/Background/ML"
import Footer from "../../components/Footer/Footer";
const MachineLearning = () => {
  return (
    <>
    <ML />
      <Hero />
      <Features />
      <Footer />
    </>
  );
};

export default MachineLearning;
