import Hero from "../../components/Services/Cybersecurity/Hero";
import Features from "../../components/Services/Cybersecurity/Features";
import CyberSecurity from "../../components/Background/CyberSecurity";
import Footer from "../../components/Footer/Footer";
const Cybersecurity = () => {
  return (
    <>
    <CyberSecurity />
      <Hero />
      <Features />
      <Footer />
    </>
  );
};

export default Cybersecurity;
