import React from "react";
import styled from "styled-components";
import { Slide } from "react-awesome-reveal";
import logo from "../assets/BLACKCRAB_PNG-06.png";
import { useState } from "react";
import ScrollDownButton from "../ScrollDownButton/ScrollDownButton";

const ProfComponent = () => {
  const scrollToContent = () => {
    // Scroll to a specific position on the page (e.g., the content section)
    const contentElement = document.getElementById("service");
    if (contentElement) {
      window.scrollTo({
        top: contentElement.offsetTop,
        behavior: "smooth", // Use smooth scrolling animation
      });
    }
  };

  const [showText, setShowText] = useState(false);

  const toggleText = () => {
    setShowText(!showText);
  };

  const buttonText = showText ? "Show Logo" : "Show Text";

  const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 12rem;
    margin-bottom: 5.15rem;
    font-size: 1.5rem;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  `;

  const content = showText ? (
    <TextContainer>
      <p>
        Welcome to Blackcrab, where innovation knows no limits. Our journey in
        the realm of information technology began with a simple yet powerful
        motto: "Make It Possible." As a newly founded IT company, we're on a
        mission to turn this motto into a reality for businesses worldwide.
      </p>
    </TextContainer>
  ) : (
    <Profile>
      <img src={logo} alt="logo" />
    </Profile>
  );

  return (
    <Container id="home">
      <Slide direction="down">
        <button
          style={{
            borderRadius: "50%",
            backgroundColor: "transparent",
            border: "none",
          }}
          onClick={toggleText}
        >
          {content}
        </button>
      </Slide>
      <Slide direction="up">
        <Texts>
          <h1 className="green center-items">BLACKCRAB</h1>
          <h3 className="center-items">Where Technology Meets Possibility</h3>
          <div className="center-items" onClick={scrollToContent}>
            <ScrollDownButton />
          </div>
        </Texts>
      </Slide>
    </Container>
  );
};

export default ProfComponent;

const Container = styled.div`
  display: flex;
  text-align: justify;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding-top: 3rem;
  width: 50%;
  max-width: 1280px;
  margin: 0 auto;
  z-index: 1;

  @media (max-width: 840px) {
    width: 80%;
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

const Texts = styled.div`
  flex: 1;

  h1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 4rem;
    letter-spacing: 2px;
  }

  h3 {
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 1.2rem;
    text-transform: capitalize;

    @media (max-width: 428px) {
      font-size: 0.8rem;
    }
  }

  p {
    font-weight: 300;
  }

  button {
    padding: 0.7rem 2rem;
    margin-top: 1rem;
    cursor: pointer;
    background-color: #993399;
    border: none;
    color: #fff;
    font-weight: 500;
    border-radius: 15px;

    :hover {
      filter: drop-shadow(0px 0px 10px #993399);
    }
  }
`;

const Profile = styled.div`
  background-color: black;
  margin-top: 8rem;
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 30px #993399;
  transition: background-color 0.4s ease-in-out, box-shadow 0.4s ease-in-out;

  :hover {
    box-shadow: 0px 0px 80px #993399;
    background-color: black;
  }

    img {
      margin-bottom:1rem;
    width: 100%;
    transition: transform 0.4s ease-in-out;

    :hover {
      transform: scale(1.3);
    }
  }
`;
