import Hero from "../../components/Services/CloudServices/Hero";
import Features from "../../components/Services/CloudServices/Features";
import Cloud from "../../components/Background/Cloud"
import Footer from "../../components/Footer/Footer";

const CloudServices = () => {
  return (
    <>
    <Cloud />
      <Hero />
      <Features />
      <Footer />

    </>
  );
};

export default CloudServices;
